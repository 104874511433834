<template>
  <div class="topbar">
    <div class="container">
      <div class="brand">
        <router-link to="/">
          <img src="/svgs/logo_2.svg" />
        </router-link>
      </div>
      <nav :class="{ collapsed: !navbarActive }">
        <ul>
          <li
            v-for="(m, i) of menus"
            :key="i"
            :class="{ active: liActive(m.to) }"
          >
            <template v-if="m.children.length">
              <a
                href="#"
                class="submenu-head"
                @mouseleave="onSubmenuMouseLeave"
                @click.prevent="toggleSubmenu(m.title.split(' ').join('_'))"
              >
                {{ $lang(m.title, true) }}
              </a>
              <ul
                id="submenu"
                :class="{
                  hidden: showSubmenu !== m.title.split(' ').join('_'),
                }"
              >
                <li v-for="(sm, si) of m.children" :key="si">
                  <router-link :to="sm.to">{{
                    $lang(sm.title, true)
                  }}</router-link>
                </li>
              </ul>
            </template>
            <a
              href="#"
              @click.prevent="openPriceModal"
              v-else-if="m.priceModal"
            >
              {{ $lang(m.title, true) }}
            </a>
            <router-link :to="m.to" v-else>
              {{ $lang(m.title, true) }}
            </router-link>
          </li>
          <li class="lang-li mt-5" :class="{ active: liActive('/en') }">
            <router-link :to="{ name: $route.name, params: { lang: 'en' } }">
              English
            </router-link>
          </li>
          <li class="lang-li" :class="{ active: liActive('/es') }">
            <router-link :to="{ name: $route.name, params: { lang: 'es' } }">
              Español
            </router-link>
          </li>
          <li class="lang-li" :class="{ active: liActive('/pt') }">
            <router-link :to="{ name: $route.name, params: { lang: 'pt' } }">
              Português
            </router-link>
          </li>
          <li class="lang-li" :class="{ active: liActive('/pl') }">
            <router-link :to="{ name: $route.name, params: { lang: 'pl' } }">
              Polski
            </router-link>
          </li>
          <li class="lang-li" :class="{ active: liActive('/ru') }">
            <router-link :to="{ name: $route.name, params: { lang: 'ru' } }">
              Pусский
            </router-link>
          </li>
          <li class="lang-li" :class="{ active: liActive('/sv') }">
            <router-link :to="{ name: $route.name, params: { lang: 'sv' } }">
              Svenska
            </router-link>
          </li>
          <li class="lang-li" :class="{ active: liActive('/zh') }">
            <router-link :to="{ name: $route.name, params: { lang: 'zh' } }">
              中文 (简体)
            </router-link>
          </li>
        </ul>
        <button class="uncollapse-button" @click="toggleNavbar(false)">
          <i class="fa fa-times"></i>
        </button>
      </nav>
      <div class="lang">
        <div class="active">
          <a href="#" @click.prevent="toggleLangSubmenu">
            {{ lang.toUpperCase() }}
          </a>
        </div>
        <ul
          :class="{
            hidden: !showLangSubmenu,
          }"
        >
          <li>
            <router-link :to="{ name: $route.name, params: { lang: 'en' } }">
              English
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: $route.name, params: { lang: 'es' } }">
              Español
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: $route.name, params: { lang: 'pt' } }">
              Português
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: $route.name, params: { lang: 'pl' } }">
              Polski
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: $route.name, params: { lang: 'ru' } }">
              Pусский
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: $route.name, params: { lang: 'sv' } }">
              Svenska
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: $route.name, params: { lang: 'zh' } }">
              中文 (简体)
            </router-link>
          </li>
        </ul>
      </div>
      <button class="collapse-button" @click="toggleNavbar(true)">
        <i class="fa fa-bars"></i>
      </button>
    </div>
  </div>

  <Teleport to="#modal-container">
    <PriceModal v-if="showPriceModal" @close="closePriceModal" />
  </Teleport>
</template>

<script setup>
import menus from "@/router/menus.js";
import { ref, computed } from "@vue/reactivity";
import { useRoute } from "vue-router";
import PriceModal from "@/components/PriceModal.vue";

let navbarActive = ref(false);
let showLangSubmenu = ref(false);
let showSubmenu = ref(null);

const clickOutsideMenu = (event) => {
  const submenu = document.querySelector("#submenu");
  const isOpeningOtherMenu = event.target.classList.contains("submenu-head");
  var isClickInsideElement = submenu.contains(event.target);

  if (!isOpeningOtherMenu && !isClickInsideElement && showSubmenu.value) {
    toggleSubmenu(null);

    document.removeEventListener("click", clickOutsideMenu);
  }
};

const toggleNavbar = (active) => {
  navbarActive.value = active;
};
const toggleSubmenu = (value) => {
  showSubmenu.value = showSubmenu.value === value ? null : value;
};
const toggleLangSubmenu = () => {
  showLangSubmenu.value = !showLangSubmenu.value;
};
const onSubmenuMouseLeave = () => {
  if (showSubmenu.value) {
    document.addEventListener("click", clickOutsideMenu);
  }
};

const liActive = (route) => {
  if (!route) return false;

  const $route = useRoute();

  if (route.name) {
    return $route.name.indexOf(route.name) > -1;
  }

  return $route.path.indexOf(route) > -1;
};

const lang = computed(() => {
  return window.localStorage.getItem("lang");
});

let showPriceModal = ref(false);
const openPriceModal = () => {
  showPriceModal.value = true;
};
const closePriceModal = () => {
  showPriceModal.value = false;
};
</script>
