<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div class="modal-header">
        <button class="modal-close" @click="$emit('close')">&times;</button>

        <div class="text-center">
          <h4 class="text-secondary mb-0">
            {{ $lang("price_modal.title", true) }}
          </h4>
          <h4 class="mt-0">{{ $lang("price_modal.subtitle", true) }}</h4>
        </div>
      </div>
      <div class="modal-content">
        <div class="container">
          <div class="row" v-if="!showForm">
            <div class="col-sm-6">
              <div class="d-flex align-items-center flex-column gap-2">
                <div class="price-modal-button" @click="openForm('pricelist')">
                  <img src="/imgs/pricelist.png" height="150" />
                  <div class="cover">
                    <img src="/imgs/download_icon.png" width="50" />
                  </div>
                </div>
                <p>{{ $lang("price_modal.pricelist", true) }}</p>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="d-flex align-items-center flex-column gap-2">
                <div class="price-modal-button" @click="openForm('brochure')">
                  <img src="/imgs/brochure.png" height="150" />
                  <div class="cover">
                    <img src="/imgs/download_icon.png" width="50" />
                  </div>
                </div>
                <p>{{ $lang("price_modal.brochure", true) }}</p>
              </div>
            </div>
          </div>

          <div class="row justify-content-center" v-else>
            <div class="col-sm-10">
              <form class="price-form" id="price-form">
                <input
                  type="text"
                  :placeholder="`${$lang('contact_form.full_name', true)} *`"
                  v-model="form.full_name"
                  required
                  name="your-name"
                />
                <input
                  type="text"
                  :placeholder="`${$lang('contact_form.email', true)} *`"
                  v-model="form.email"
                  required
                  name="your-email"
                />
                <Select
                  v-model="form.country"
                  :label="`${$lang('contact_form.country', true)} *`"
                  :options="countries"
                  name="country"
                />
                <Select
                  v-model="form.nationality"
                  :label="`${$lang('contact_form.nationality', true)} *`"
                  :options="nationalities"
                  name="nationality"
                />

                <Checkbox
                  :label="$lang('contact_form.terms', true)"
                  name="terms-accepted"
                  :isInvalid="terms_invalid"
                  :class="`checkbox-${theme}`"
                />

                <button
                  type="submit"
                  @click="downloadFile"
                  class="btn btn-secondary mt-3 mx-auto px-6"
                  :class="`btn-${theme}`"
                >
                  {{ $lang("contact_form.button", true) }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import countries_es from "@/assets/json/countries-es.json";
import countries_en from "@/assets/json/countries-en.json";
import Checkbox from "@/components/Forms/Checkbox.vue";
import Select from "@/components/Forms/Select.vue";

export default {
  components: {
    Checkbox,
    Select,
  },
  data: () => ({
    showForm: false,
    form: {
      full_name: null,
      email: null,
      country: null,
      nationality: null,
      download: null,
      terms: false,
    },
    downloads: {
      brochure: "/files/brochure_2024_short_english_digital.pdf",
      pricelist: "/files/PRICELIST_PRINT_2024_ENGLISH.pdf",
    },
    countries_en,
    countries_es,
  }),
  computed: {
    countries() {
      return (
        this.$route.params.lang === "en" ? this.countries_en : this.countries_es
      ).map((x) => ({
        value: x.name,
        label: x.name,
      }));
    },
    nationalities() {
      return (
        this.$route.params.lang === "en" ? this.countries_en : this.countries_es
      ).map((x) => ({
        value: x.nationality,
        label: x.nationality,
      }));
    },
  },
  methods: {
    openForm(download) {
      this.showForm = true;
      this.form.download = download;
    },
    sendForm() {
      if (!this.isValid) return;

      axios
        .get("https://api.pumpmylead.com/integrations/informs/v1/webhook", {
          params: {
            "your-name": this.form.full_name,
            "your-email": this.form.email,
            phone: this.form.phone,
            country: this.form.country,
            nationality: this.form.nationality,
            token: "6xy3q223026n87bs119571l46942t8ngz",
          },
        })
        .then(() => {
          this.downloadFile();
          this.$emit("close");
        });
    },
    downloadFile() {
      window.open(this.downloads[this.form.download], "_blank");
    },
    isValid() {
      let valid = true;

      if (!this.form.terms) {
        this.$toast.success(this.$lang("price_modal.errors.terms", true));
        valid = false;
      }
      if (!this.form.full_name) {
        this.$toast.success(this.$lang("price_modal.errors.full_name", true));
        valid = false;
      }
      if (!this.form.email) {
        this.$toast.success(this.$lang("price_modal.errors.email", true));
        valid = false;
      }
      if (!this.form.country) {
        this.$toast.success(this.$lang("price_modal.errors.country", true));
        valid = false;
      }
      if (!this.form.nationality) {
        this.$toast.success(this.$lang("price_modal.errors.nationality", true));
        valid = false;
      }

      return valid;
    },
  },
};
</script>
