<template>
  <Topbar v-if="!$route.meta.landing" />

  <div class="main" :class="{ 'pt-0': $route.meta.landing }">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>

  <!-- <FloatingButton /> -->

  <Footer />
</template>

<script>
import Topbar from "@/components/Topbar.vue";
import Footer from "@/components/Footer.vue";
// import FloatingButton from "@/components/FloatingButton.vue";

export default {
  components: {
    Topbar,
    Footer,
    // FloatingButton,
  },
};
</script>

<style lang="scss">
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
