module.exports = [
  {
    title: "menus.about_us",
    to: "/about-us",
    children: [
      {
        title: "menus.mission_methodology",
        to: { name: "methodology" },
      },
      {
        title: "menus.our_program",
        to: { name: "our_program" },
      },
      {
        title: "menus.meet_our_team",
        to: { name: "meet_our_team" },
      },
      {
        title: "menus.frequently_asked_questions",
        to: { name: "faqs" },
      },
      {
        title: "menus.work_with_us",
        to: { name: "work_with_us" },
      },
    ],
  },
  {
    title: "menus.campus",
    to: { name: "our_campus" },
    children: [
      {
        title: "menus.barcelona",
        to: { name: "our_campus", hash: "#barcelona" },
      },
      {
        title: "menus.madrid",
        to: { name: "our_campus", hash: "#madrid" },
      },
    ],
  },
  {
    title: "menus.courses",
    to: "/courses",
    children: [
      {
        title: "menus.spanish_course",
        to: { name: "our_courses", hash: "#spanish" },
      },
      {
        title: "menus.english_course",
        to: { name: "our_courses", hash: "#english" },
      },
      {
        title: "menus.placement_test",
        to: { name: "placement_test" },
      },
      {
        title: "menus.exams_certification",
        to: { name: "exams_and_certifications" },
      },
    ],
  },
  // {
  //   title: "menus.pathway",
  //   to: { name: "pathway" },
  //   children: [],
  // },
  // {
  //   title: "menus.packages",
  //   to: { name: "our_packages" },
  //   children: [],
  // },
  {
    title: "menus.prices",
    to: null,
    priceModal: true,
    children: [],
  },
  {
    title: "menus.student_life",
    to: "/student-life",
    children: [
      {
        title: "menus.student_life",
        to: { name: "student_life" },
      },
      {
        title: "menus.student_visa",
        to: { name: "student_visa" },
      },
      {
        title: "menus.accommodation",
        to: { name: "accommodation" },
      },
      {
        title: "menus.medical_insurance",
        to: { name: "medical_insurance" },
      },
      {
        title: "menus.extra_activities",
        to: { name: "extra_activities" },
      },
    ],
  },
  // {
  //   title: "menus.blog",
  //   to: { name: "blog" },
  //   children: [],
  // },
  {
    title: "menus.contact_us",
    to: { name: "contact_us" },
    children: [],
  },
];
